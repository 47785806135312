

























































import { parseDate } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readMqttLog } from '@/store/main/getters';
import { dispatchGetLog } from "@/store/main/actions";

@Component
export default class Detail extends Vue {
  public parseDate = parseDate;

  get log() {
    return readMqttLog(this.$store);
  }

  public parseMessage(message){
    try{
      return JSON.parse(message);
    }
    catch(e){
      return '';
    }
  }

  public async mounted() {
    await dispatchGetLog(this.$store, Number(this.$router.currentRoute.params.id));
  }

  public goBack() {
    this.$router.push('/main/mqtt_logs/all');
  }
}
